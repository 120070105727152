import React from "react"
import AwardCategoryIcon from "./award-category-icon"
import Row from "./bootstrap/row"

const AwardCategoryShortlist = ({children, title, subtitle, icon, gridColor}) => (
  <div className="award-category-shortlist" style={{
    position: `relative`
  }}>
    <div style={{
      position: `absolute`,
      width: `100%`,
      height: `100%`,
      background: `repeating-linear-gradient(-45deg, ${gridColor}, ${gridColor} 4px, transparent 4px, transparent 10px)`,
      top: `-1rem`,
      right: `-1rem`,
      zIndex: `-1`
    }} />
    <div className="container" style={{
      backgroundColor: `#fff`,
    }}>
      <Row>
        <div style={{
          backgroundColor: `#30c4bf`,
          padding: `1.5rem`,
          position: `relative`
        }}>
          <div style={{
            position: `absolute`,
            width: `4rem`,
            height: `4rem`,
            background: `repeating-linear-gradient(-135deg, ${gridColor}, ${gridColor} 2px, transparent 2px, transparent 4px)`,
            top: `1.5rem`,
            right: `1.2rem`,
            borderRadius: `50%`
          }}>
          </div>
          <AwardCategoryIcon icon={icon} size="4rem" />
        </div>
        <div className="col" style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1.3rem`,
          color: `#fff`,
          background: `linear-gradient(270deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
          // padding: `2.5rem 2rem`,
        }}>
          <div className="row h-100">
            <div className="col align-self-center">
            {title}{subtitle && <><br /><small>{subtitle}</small></>}
            </div>
            {/* <div className="col">
              <div className="h-100 border">logo</div>
            </div> */}
          </div>
        </div>
      </Row>
      <div className="children">
        {children}
      </div>
    </div>
  </div>
)

export default AwardCategoryShortlist
