import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import Row from "../components/bootstrap/row"

import AwardCategoryShortlist from "../components/award-category-shortlist"
import ShortlistOrganisation from "../components/shortlist-organisation"
import PrimaryButton from "../components/primary-button"

const ShortlistPage = () => (
  <Background>
    <Layout>
      <Seo title="Shortlist" />
      <div hidden>
        <Container>
          <PageTitle text="Shortlist" />
        <div style={{
          textAlign: `center`,
          backgroundColor: `rgba(0,0,0,0.6)`
        }}>
          <div style={{
            maxWidth: `34rem`,
            margin: `auto`,
            padding: `5rem 2rem 1rem 2rem`
          }}>
            <span style={{
              display: `inline`,
              backgroundColor: `#d23464`,
              padding: `.5rem 0`,
              lineHeight: `3.4rem`,
              boxShadow: `.5rem 0 0 #d23464, -.5rem 0 0 #d23464`,
              textTransform: `uppercase`,
              color: `#fff`,
              fontFamily: `Din Pro`,
              fontWeight: `700`,
              fontSize: `1.6rem`
            }}>Key Dates</span>
          </div>
          <Container>
            <div className="row" style={{
                  color: `#fff`,
                  padding: `2rem`
                }}>
              <div className="col-md">
                <h4 style={{
                  textTransform: `uppercase`,
                  fontSize: `1.2rem`
                }}>Entry Deadline</h4>
                <p>Closed on 9th December 2022</p>
              </div>
              <div className="col-md">
                <h4 style={{
                  textTransform: `uppercase`,
                  fontSize: `1.2rem`
                }}>Shortlist Announcement</h4>
                <p>23rd January 2023</p>
              </div>
              <div className="col-md">
                <h4 style={{
                  textTransform: `uppercase`,
                  fontSize: `1.2rem`
                }}>Awards Ceremony Date</h4>
                <p>23rd March 2023</p>
              </div>
            </div>
          </Container>
        </div>

        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`
        }}>
          <Container>
            <div className="row" style={{ padding: `2rem`}}>
              <div className="col-lg">
                <h5>Thank You</h5>
                <p>The entry deadline has now passed and your nominations are being evaluated by our judges.</p>
                <p>We will be announcing the shortlist on this page in January with the awards ceremony taking place in March.</p>

                <h5>Questions</h5>
                <p>If you have any questions, please email us on <a href="mailto:entries@smarterworkinglive.com" style={{color: `#fff`}}>entries@smarterworkinglive.com</a> or call <a href="tel:+441616973438" style={{color: `#fff`}}>0161 697 3438</a>.</p>
              </div>
              <div className="col-lg">
                <h5>Reminder</h5>
                <p>Please enter your email address and click submit to receive a reminder when the shortlist is announced.</p>

                <form
                  id="entries"
                  name="entries"
                  method="post"
                  onSubmit={(e) => {
                    e.preventDefault()
                    let myForm = document.getElementById('entries')
                    let formData = new FormData(myForm)

                    fetch('https://hook.eu1.make.com/oc4q2v3c3wu4dqi8r38ivy7sk7ppi3w1', {
                      method: 'POST',
                      headers: { "Content-Type": "application/x-www-form-urlencoded" },
                      body: new URLSearchParams(formData).toString()
                    }).then(() => { 
                      console.log('Form successfully submitted')
                      document.getElementById('formstatus').innerHTML = '<p>Thank you for registering for the shortlist reminder. We will be in touch when the shortlist is announced.</p>'
                      if(window.dataLayer) {
                        window.dataLayer.push({event: 'form-registration-download', 'gtm.elementId': 'entries', 'gtm.elementUrl': undefined});
                      }
                    }).catch((error) => alert(error))
                  }}
                >
                  <p style={{display: `none`}}>
                    <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                  </p>
                  <input type="hidden" name="form-name" value="entries" />
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <input type="text" className="form-control" id="Email" name="email" placeholder="Email" required />
                      </div>
                    </div>
                  </div>
                  <div id="formstatus"></div>
                  <button type="submit" className="btn btn-primary" style={{
                    backgroundColor: `rgb(255, 255, 255)`,
                    borderColor: `rgb(255, 255, 255)`,
                    color: `rgb(210, 52, 100)`,
                    textTransform: `uppercase`,
                    borderRadius: 0,
                    padding: `0.6rem 1rem`,
                    fontWeight: 600,
                  }}>Submit</button>
                </form>

              </div>
            </div>
          </Container>
        </div>

        <br /><br />

        </Container>
      </div>
      <div>
        <Container>
          <PageTitle text="Shortlist" />
          <p style={{
            textTransform: `uppercase`,
            color: `#fff`,
            fontWeight: `bold`,
            textAlign: `center`,
            maxWidth: `750px`,
            margin: `auto`,
            marginBottom: `50px`,
          }}>Congratulations to all organisations who have made it to the 2024 shortlist.</p>

          <div className="text-center mb-5">
            <PrimaryButton to="/awards/" text="Book a table for the ceremony" />
          </div>

          <AwardCategoryShortlist title="Improving Spaces" subtitle="Creating a Great Place to Work" gridColor="#d23464" icon="computer-desk">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Avon Fire & Rescue Service" project="Avonmouth - Reinvesting for the Future." />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="BLMK Integrated Care Board (ICB)" project="Supporting Muslim colleagues during Ramadan 2024 – Information pack for staff" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Brooklands College" project="Staffing for the FUTURE!" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Hertfordshire County Council" project="Our Workplaces: Thriving spaces where you can meet, connect and work together." />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HMP Birmingham" project="Two Projects for Improving Spaces for Residents and Staff" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Kent County Council" project="Kent Connects and Kent Estates Shared Workspace" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Oadby & Wigston Borough Council" project="A new home for Oadby & Wigston Borough Council" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="South Cambridgeshire District Council" project="Four Day Week Trial" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="West Berkshire Council" project="Walnut Close - a fresh start for families" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Worcestershire Acute Hospitals NHS Trust" project="Re-energising Library spaces for staff wellbeing and relaxation: a place of knowledge and care" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Future Focused and Sustainable Property" gridColor="#30c4bf" icon="office">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department Business and Trade" project="Darlington Economic Campus - Collaboration supporting Policy, Places and People" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department for Work and Pensions" project="DWP Innovation Hubs (Jobcentres)" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Government Legal Department" project="Accommodation & Location Team - Re-purposing floors in 102 Petty France" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Hertfordshire County Council" project="Our Workplaces: Thriving spaces where you can meet, connect and work together." />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Kent County Council" project="Kent Connects and Kent Estates Shared Workspace" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Norfolk County Council" project="Innovative Future Network Programme" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Oadby & Wigston Borough Council" project="A new home for Oadby & Wigston Borough Council" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Scottish Forestry" project="Future Working at Scottish Forestry" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="West Berkshire Council" project="Walnut Close - repurposing assets for improved outcomes" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="West Yorkshire Fire and Rescue" project="Cookridge Fire Station Refurbishment" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Innovation as a Service" gridColor="#30c4bf" icon="innovation">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Blüm Health with Mersey and West Lancashire NHS Trust" project="Tailored Innovation Solutions for the Public Sector" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="East Staffordshire Primary Care Network" project="Embedding Research in Primary Care and Enhancing Community Collaboration" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Revenue and Customs" project="Learning at Work Week 2024" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Midlands Partnership University NHS Foundation Trust" project="Primary and Community Care Innovation" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Oxfordshire County Council" project="Oxfordshire Innovation Transformation" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="South Tyneside and Sunderland NHS Foundation Trust" project="Innovation Team" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="South Yorkshire ICB - Rotherham Place" project="The Rotherham Care Homes Hydration Project" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Swindon Borough Council" project="Simply Readable; co-created with Experts by Experience of a Learning Disability" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="West Berkshire Council" project="Walnut Close" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="West Dunbartonshire Council" project="Fit for Future" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />

          <AwardCategoryShortlist title="Digital Innovation as a Service" gridColor="#30c4bf" icon="digital-innovation">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Citizens Advice Scotland" project="Digital Transformation Work Since 2020" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Environment Agency" project="Apply for and manage environmental permissions service" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HelpFirst with Citizens Advice Scotland" project="AI to support vulnerable service users" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="London Borough of Barking & Dagenham Council" project="Enhancing Service, Supporting Vulnerable Customers, Promoting Agent Wellbeing" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Norfolk County Council" project="Bin Lorry Innovation" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="North Sea Transition Authority" project="National Data Repository - Revolutionising Access to Licencing Information" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Royal Air Force (Astra Team)" project="Project BLUEPRINT" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Royal Papworth Hospital" project="Scan4Safety: Blood Track" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Somerset Council" project="Somerset Council's innovative approach to replacing its externally provided health and safety system" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Virtual Decisions with Midlands Partnership University NHS Foundation Trust" project="Virtual_Decisions: IDENTITY" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />

          <AwardCategoryShortlist title="Harnessing the Value of Data" gridColor="#d23464" icon="binary">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="East Sussex County Council" project="Data to Insight, the sector-led service for children's services data professionals" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Falkirk Council" project="OneSource: Making a Difference with Data – Pupil Absence and Attendance" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Revenue and Customs" project="Embedding Hybrid Working Toolkit and Attendance View" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Midlands Partnership University NHS Foundation Trust" project="Data-Driven Transformation" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Mobysoft with Lambeth Borough Council" project="Sustaining Tenancies Through Intelligent Automation" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Mobysoft with Warwick District Council" project="Automated Arrears Prevention" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Nexus" project="In-House Research Team - Insight into Action" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Primary Care Analytics" project="Embedding a new approach to analytics across primary care" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University Hospital Dorset" project="Intelligent Workflows" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University of Birmingham" project="Research Dashboard" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />

          <AwardCategoryShortlist title="Customer Experience" gridColor="#d23464" icon="customer-experience">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Dundee City Council" project="Dundee Local Fairness Initiatives" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Environment Agency" project="Apply for and manage environmental permissions service" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Harrogate and District NHS Foundation Trust" project="Harrogate Patient Post Procedure Innovation" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HelpFirst with Citizens Advice Scotland" project="HelpFirst - intelligent case notes for the CAS Extra Help Unit" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Lichfield District Council, Staffordshire Moorlands District Council & Staffordshire County Council" project="Single Front Door Programme" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="London Borough of Barking & Dagenham Council" project="Enhancing Service, Supporting Vulnerable Customers, Promoting Agent Wellbeing" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="London Borough of Redbridge" project="How do you redesign Adults Social Care to give better outcomes for residents?" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="UK Home Office" project="Transforming Home Office Contact Centres - Innovative, Scalable, Empathy-Driven Solutions" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="West Berkshire Council" project="Walnut Close - a fresh start for families" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Westminster City Council" project="Report It - A new Human Centred Digital Experience" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />

          <AwardCategoryShortlist title="User Experience" gridColor="#d23464" icon="user-experience">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Blackburn with Darwen Borough Council" project="Fostering Service Transformation Project" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Environment Agency" project="Nexus Enforcement" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HelpFirst with Citizens Advice Scotland" project="Helpline - AI voice assistant" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Revenue and Customs" project="Matching Task to Space" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Ministry Of Justice – Property and People functions" project="Our People, Our Future – Hybrid Working User Experience" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="North Sea Transition Authority" project="New and Improved Website - Putting Users First" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Outcomes Matter Consulting" project="EHCP Plus - transforming SEND through AI" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Oxleas NHS Foundation Trust" project="Digital Audit Tool (Trust brand - AIM- Audit and Improvement Management) " />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Tewkesbury Borough Council" project="User centric innovation: The art of user experience  " />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University Hospitals Coventry and Warwickshire NHS Trust" project="Transforming People Services through digital innovation and automation." />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />

          <AwardCategoryShortlist title="Smarter Workflows" gridColor="#d23464" icon="workflows">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="AgilePath with NHS" project="Supercharging Cancer Diagnostics with Smarter Workflows for better patient care" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Archway Medical Centre" project="Smarter Workflows at Archway Medical Centre" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Dorset Council" project="Utilising the power of data and technology to ensure every child the right to education." />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Falkirk Council" project="OneSource: Making a Difference with Data – Pupil Absence and Attendance" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Humberside Police" project="Enhanced Victim Engagement Proof Of Concept" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Royal Papworth Hospital" project="Scan4Safety: Blood Track" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Soprano Design with the NHS" project="Leveraging Conversational AI to Reduce Did Not Attends & Streamline Clinical Pathways" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Tower Family Healthcare" project="GPnav - introducing our home grown care navigation tool for Primary Care" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University Hospital Dorset" project="Intelligent Workflows" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University Hospitals of Morecambe Bay NHS Foundation Trust" project="eConsent" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />

          <AwardCategoryShortlist title="Digital Inclusion" gridColor="#d23464" icon="inclusion">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Archway Medical Centre" project="Virtually Consult a smarter working technology to ensure equitable access for diverse audiences" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="London Borough of Camden" project="Camden's Digital Inclusion Challenge - Outreach Bus and Minimum Digital Living Standards" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="London Borough of Tower Hamlets" project="Connecting Communities: Empowering Lives, One Laptop at a Time" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Midlands Partnership University NHS Foundation Trust" project="The Digital Angels" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Norfolk County Council" project="Tech Skills for Life" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Norfolk County Council" project="Improving connectivity to Support Digital Inclusion" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="South Tyneside and Sunderland NHS Foundation Trust" project="Cancer Information Hub- South Tyneside and Sunderland NHS Foundation Trust" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Trafford Council" project="Trafford Tech4All" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Walsall Council" project="Walsall Connected" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="West Yorkshire Fire and Rescue Service" project="OneView Programme" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Automation, Artifical Intelligence and Machine Learning" gridColor="#d23464" icon="brain">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department for Environment, Food and Rural Affairs" project="AI Assistant to help draft Defra Ministerial Correspondence" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Mobysoft with Karbon Homes" project="Driving Repairs Service Efficiencies for The Benefit of Tenants" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Outcomes Matter Consulting" project="EHCP Plus - transforming SEND services through AI" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Sandwell and West Birmingham NHS Trust" project="Imagine - Making smart use of large volumes of Imaging scan data for earlier interventions" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Soprano Design with Breckland Council" project="Conversational AI implementation with chatbot, VoiP and Alexa" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Swindon Borough Council" project="Simply Readable; co-created with Experts by Experience of a Learning Disability" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University Hospitals Coventry and Warwickshire NHS Trust" project="Transforming People Services through digital innovation and automation" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Valuation Office Agency" project="Building a world leading automated valuation model for Council Tax reform in Wales" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="West Oxfordshire and Cotswold District Councils" project="Innovation driven improvements in the Local Plan process" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Westminster City Council" project="Westminster Report It Image and Generative AI tool" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Building Collaborative Communities" gridColor="#30c4bf" icon="circle-people">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Community Planning Aberdeen" project="Supporting Young Carers  in Aberdeen City" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Cross Government Climate Hub" project="The Cross Government Climate Hub" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Defence Infrastructure Organisation" project="Smarter Working Programme at DIO - One size certainly does not fit all." />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department Business and Trade" project="Darlington Economic Campus - Collaboration supporting Policy, Places and People" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department for Environment, Food and Rural Affairs" project="Defra Group Innovation 2024" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Dundee City Council" project="Dundee Local Fairness Initiatives" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Nottingham Trent University" project="Getting School Ready" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Scottish Forestry" project="Future Working at Scottish Forestry" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Sheffield City Council" project="Multiply Sheffield" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Walsall Council" project="Walsall Connected" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Small-scale, Big Impact" gridColor="#30c4bf" icon="inward-arrows-square">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="England Illegal Money Lending Team" project="The England Illegal Money Lending Team" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Environment Agency" project="Apply for and manage environmental permissions service" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Essex + Herts Digital Innovation Zone" project="Essex + Herts Digital Innovation Zone (DIZ) - A partnership for place" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Harrogate and District NHS Foundation Trust" project="Harrogate Patient Post Procedure Innovation" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Home Office" project="Smart lockers end user compute and collaboration" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Kent County Council" project="Kent Connects and Kent Estates Shared Workspace" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Medical Examiners Office hosted by Ashford & St Peter's Hospitals NHSFT" project="Medical Examiners Service: a no-cost solution for a brand new Service" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Somerset Council" project="How Copilot 365 I&C Sub-Group Built a Collaborative Community of Practice" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="South Tyneside and Sunderland NHS Foundation Trust" project="Shoulder Reduction Bench Project" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="The Christie NHS foundation Trust" project="The Green Team Competition: Implementing small Quality Improvement projects with a big impact" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Putting People First" gridColor="#d23464" icon="inspire">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Avon Fire & Rescue Service" project="The Big Shout - Internal Newspaper" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="City of Bristol College" project="Leadership & Recognition - The way to create a holistic people first culture" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Community Planning Aberdeen - Alcohol and Drug Partnership" project="Reaching out to people at risk and removing barriers to accessing drug supports" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department Business and Trade" project="Putting People First - The One DBT Story" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Greater Manchester Police" project="Armed Forces Network" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HelpFirst with Citizens Advice Scotland" project="Intelligent Case Notes for the CAS Extra Help Unit" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Kent Connects and Kent Estates Partnerships" project="Kent Connects and Kent Estates Shared Workspace 2024" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Local Partnerships LLP" project="Transforming Tradition: Building a Diverse, Inclusive, and Agile Workforce" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Nexus" project="Our People" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="South Cambridgeshire District Council" project="Four-Day Week Trial" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Championing Continuous Improvement" gridColor="#d23464" icon="three-up-arrows">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Alternative Futures Group" project="Alternative futures a step into personalized care" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department for Work and Pensions" project="Move to Universal Credit" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Ealing Council - Ealing Learning Partnership" project="Ealing Learning Partnership (ELP)" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Greater Cambridge Shared Planning" project="Championing Continuous Improvement" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Greater Manchester Combined Authority and Transport for Greater Manchester" project="Information and Data Governance Change Programme" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Kent County Council" project="Kent Estates and Kent Connects Shared Workspace" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="London Borough of Tower Hamlets" project="Corporate Strategy & Transformation" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Ofwat - The Water Regulators" project="Continuous Improvement at Ofwat; What if you could change anything - What would you change?" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University Hospitals of Derby and Burton NHS Foundation Trust" project="Continuous learning and improvement in our organisations strategy to deploy PSIRF" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University Hospitals of Derby and Burton NHS Foundation Trust" project="Digitising Essential Safety Guides for nurses and healthcare support workers" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Outstanding Smarter Working Leadership" gridColor="#30c4bf" icon="leader">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Barking Havering Redbridge University Trust Palliative Care Team" project="Palliative Care in ED - Modelling best practice in providing outstanding compassionate care" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="British Tourist Authority" project="Locate for your Day - dropping nugatory office attendance targets" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Greater Cambridge Shared Planning" project="Organisational Leadership – Creating a smarter working culture" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Revenue and Customs" project="Transforming the Way We Work Programme" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Integrated Care Partnership" project="FCP Project" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Kent County Council" project="Kent Connects and Kent Estates Shared Workspace" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="London Borough of Tower Hamlets" project="Corporate Strategy & Transformation Team" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Ofcom" project="Powered by Agility" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Smart Working Revolution" project="Smart Working Leadership Programmes" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="UK Shared Business Services" project="Outstanding Smarter Working Leadership" />
              </div>
            </Row>
          </AwardCategoryShortlist>

          <div className="text-center mt-5">
            <PrimaryButton to="/awards/" text="Book a table for the ceremony" />
          </div>

          <br /><br /><br /><br />

        </Container>
      </div>
    </Layout>
  </Background>
)

export default ShortlistPage
